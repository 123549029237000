import React, {useState, useEffect} from 'react';
import answersData from '../data/answersData.json'
import variablesData from '../data/variables.json'
import initialResultObjects from '../data/variablesResult.json'
import {filter, std} from 'mathjs'

export const StateContext = React.createContext();

function StateContextProvider(props) {

    // console.log(answersData.length)

    const initialFilter = {
        "Kategori":'all',
        "hasCost":'all',
        "median":0, //0 = all, 1 = higher than 5.5, -1 = lower or equal 5.5
        "hasValue":'all',
        "offered_NNIT":'all',
        "exp": 'all'
    }

    const testSortObj = {
        group:"mad",
        direction:[1,-1]
    }

    const [segment, setSegment] = useState(answersData)
    const [filterObj,setFilterObj] = useState(initialFilter)
    const [filteredResults, setFilteredResults] = useState()
    const [sortObj,setSortObj] = useState();
    const [oplevetCVS, setOplevetCVS] = useState();

    // const [selectOptionsState, setselectOptionsState] = useState();


    const segmentsOptions = {
        "age":[],
        "superior":[],
        "team":[],
        "senioritDate":[],
        "gender":[],
        "office":[],
        "positionDescription":[],
        "salaryBandLevel":[],
        "toe":[]
    }
    const ignore = ["fornavn","answers","factor", "birthDay","salary", "vp","benefit"]

    answersData.forEach(emp => {
        Object.keys(emp).forEach(key => {
            // console.log(key)
            if(ignore.includes(key))return; 
            if(segmentsOptions[key].includes(emp[key])) return;
            segmentsOptions[key].push(emp[key])
        })
    })
    // console.log(segmentsOptions)
    // setselectOptionsState(segmentsOptions)
    
    useEffect(() => {

        // genereated list of terms (variablesResult.js)
        // const variablesResult = {}
        // variablesData.filter(v => v.use === "TRUE").forEach(f => {
        //     const obj = {
                
        //         weight:[]
        //     }
        //     if(f.hasExperience_NNIT === "Ja") {
        //         obj.exp = []
        //     }
        //     if(f.ID !== "ft_01") {
        //         variablesResult[f.ID.toLowerCase()] = obj;
        //     }
        // })
        console.log("segmnet changed")
        calculateResult()
        
    }, [segment]);

    function resetSegment() {
        setSegment(answersData)
    }

    function filterTerm(obj) {
        // console.log(filterObj)
        let isShown = Object.keys(filterObj).every(test => obj[test] === filterObj[test] || filterObj[test] === "all" || test === 'median' || test === "exp")
        
        if(isShown && filterObj.median > 0) {
            isShown = parseFloat(obj.median) > 5.5
        }
        if(isShown && filterObj.median < 0) {
            isShown = parseFloat(obj.median) <= 5.5
        }

        //FINDES I MATRIX???
        if(isShown && filterObj.exp === 'Ja') {
            const thisResult = filteredResults[obj.ID.toLowerCase()]
            // console.log()
            isShown = thisResult.exp ? true : false
        }
        if(isShown && filterObj.exp === 'Nej') {
            const thisResult = filteredResults[obj.ID.toLowerCase()]
            // console.log()
            isShown = thisResult.exp ? false : true
        }
        // console.log(`filtering = ${isShown}`)
        // let isShown = Object.keys(obj).every(subseg => obj[subseg].includes(emp[subseg]) || obj[subseg].includes("all"));

        return isShown
    }

    useEffect(() => {
        // filterTerm(filterObj)

    }, [filterObj]);
    // age = over eller under værdi
    // vp = dropdown
    // superior =dropdown
    // team = dropdown
    // senioritDate = over eller under værdi
    // gender = dropdown
    // office = dropdown
    // positionDescription = dropdown
    // salaryBandLevel = dropdown
    // toe = time betalt eller ej : dropdown
    // benefit

    function filterResults(obj = null, count = false, isMulti = false) {
        let newSegment = []
        // const scope = [...answersData]
        const scope = count && !isMulti ? [...segment] : [...answersData]
        // const oldLength = scope.length;
        //CONVERT TO ARRAY
        Object.keys(obj).forEach(group => {
            // console.log(group)
            if(!Array.isArray(obj[group])) {
                obj[group] = [obj[group]]
            }
            
        })
        //SEGMENTING
        scope.forEach(emp => {
            const filter = Object.keys(obj).every(subseg => obj[subseg].includes(emp[subseg]) || obj[subseg].includes("all"))
            if(filter) {
                newSegment.push(emp)
            }
        })
            
        if(count) {
            if(isMulti) {
                // console.log(newSegment.length, segment.length)
                const int = newSegment.length > segment.length ? newSegment.length + segment.length : newSegment.length;
                // return newSegment.length > segment.length ? {int:int,str:`(${int})`} : {int:int,str:`(+${int})`}
                return {int:int, str:`(${int})`}
            }
            // && newSegment.length > segment.length 
            return {int:newSegment.length,str:`(${newSegment.length})`}
        } else {
            setSegment(newSegment)
        }
        return

    }

    function countSegment() {
        if(filteredResults) {
            return filteredResults.af_01.weight.length
        }
    }


    function calculateResult() {
        const newResults = {...initialResultObjects}
        console.log(initialResultObjects)

        Object.keys(newResults).forEach(id => {
            Object.keys(newResults[id]).forEach(propName => {
                newResults[id][propName] = [];
            })
        })

        segment.forEach(emp => {
            // console.log(emp)
            Object.keys(emp.answers).forEach(a => {

                if(a !== "ft_01") {
                    // console.log(a)
                    // newResults[a].weight = []
                    newResults[a].weight.push(parseInt(emp.answers[a].Vægt))
                    if(emp.answers[a].Oplevet) {
                        // newResults[a].exp = []
                        newResults[a].exp.push(parseInt(emp.answers[a].Oplevet))
                    }
                }

            })
        })
        Object.keys(newResults).forEach(id=> {
            newResults[id] = {
                ...newResults[id],
                ...deviation(newResults[id].weight, id),
            }
            if(newResults[id].exp) {
                newResults[id] = {
                    ...newResults[id],
                    ...deviation(newResults[id].exp, id, "exp"),
                }

            }
            // console.log(deviation(filteredResults[id].weight,id),id)
        })
        // console.log(newResults)

        getOplevet(newResults)
        console.log(newResults)
        setFilteredResults(newResults)
    }

    function getOplevet(currentResult) {
        // console.log(currentResult)
        const cvs = [['id','name','oplevet','præf']]
        Object.keys(currentResult).forEach(id => {
            if(currentResult[id].exp) {
                const upperId = id.toUpperCase()
                const arr = [...currentResult[id].exp]
                const sum = arr.reduce((acc, a) => acc + a, 0);
                const median = sum / arr.length;
                const row = [id,variablesData[upperId].Element,median,currentResult[id].median]
                cvs.push(row)

            }
        })
        setOplevetCVS(cvs);
        
    }

    function deviation(arr,id, type = "pref") {

        const important = arr.reduce((acc,a) => a > 7 ? acc+1 : acc, 0)

    
        const sum = arr.reduce((acc, a) => acc + a, 0);
        const median = sum / arr.length;
        
        const fordeling = [0,0,0,0,0]
        let max, min;
        if(type === "pref") {
            max = Math.max(...arr)
            min = Math.min(...arr)
        
            arr.forEach(w => {
                // console.log(`v:${w}, placering:${Math.round(w/2)-1}`)
                const placement = Math.round(w/2)-1
                //COUNT UP HOW MANY IS WHERE IN PREFERENCE
                fordeling[placement]++
            })
        }
        // console.log(fordeling)
        

        // median = math.median(arr);
        // console.log(id)
        // console.log(arr)
        return type === "exp" ? {expMedian:median} :{
            mad:std(arr),
            median:median.toPrecision(3), 
            min:min,
            max:max, 
            important:important, 
            fordeling:fordeling
        };
        // return {mad:math.std(arr),median:median, min:min,max:max, important:important};
    }

    const context = {
        filteredResults:filteredResults,
        filterResults:filterResults,
        segmentsOptions:segmentsOptions,
        filterObj:filterObj,
        filterTerm:filterTerm,
        setFilterObj:setFilterObj,
        initialFilter:initialFilter,
        resetSegment:resetSegment,
        sortObj:sortObj,
        setSortObj:setSortObj,
        oplevetCVS:oplevetCVS,
        countSegment:countSegment
    }

    return (
        <StateContext.Provider
            value={context}
        >
            {props.children}
            
        </StateContext.Provider>
    );
}

export default StateContextProvider;

//EXPORT TO CSV MEDIAN af OPLEVET og PREF