import React, {useState, useEffect, useContext} from 'react';
import { StateContext } from '../context/StateContext';
import CustemSelect from './CustomSelect';

function SortingControl(props) {

    const {setSortObj} = useContext(StateContext)
    
    const options = [
        {value:[-1,1] , label:"Præf - Høj til lav", group:"median"},
        {value:[1,-1], label:"Præf - Lav til høj", group:"median"},
        {value:[-1,1] , label:"Spredning - Høj til lav", group:"mad"},
        {value:[1,-1], label:"Spredning - Lav til høj", group:"mad"},
    ]

    function sort(e) {
        const newObj = {
            group:e.group,
            direction: e.value 
        }
        setSortObj(newObj)
    }
    
    return (
        <div style={{...props.style}}>
            <CustemSelect 
                isMulti={false}
                options={options}
                filter={sort}
                clear={false}
                label="Sort"
                width="100%"
            />

        </div>

    )
}

export default SortingControl