import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const SelectContainer = styled.div`
    display:flex;
    flex-direction:row;
    margin: 0.25rem;
    max-height:2.5rem;
    /* width:100%; */
    min-width:20rem ;

    & > p{
        width:25%;
        align-self:center;
        text-align:right;
        margin-right:1rem;
        /* justify-self: flex-end; */
    }
    & > div {
        width:75%;
    }

`
const renamingObj = {
    "age":"Age",
    "vp":"VP",
    "superior":"Superior",
    "team":"Team",
    "senioritDate":"all",
    "gender":"Gender",
    "office":"Office",
    "positionDescription":"Position",
    "salaryBandLevel":"Salary Level",
    "toe":"TOE",
    "Kategori":"Kategori",
    "offered_NNIT":"Tilbudt",
    "Sort":"Sort",
    "exp":"Matrix"
}

function CustemSelect(props) {
    
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: 'none',
          color:'black',
          padding:'0.5rem',
          fontSize:'1.5rem',
          opacity: state.isDisabled ? "0.2" : "1",
          ":hover": {
            backgroundColor: state.isDisabled ? "none" : "#A8DADD"
          },
          backgroundColor: state.isFocused ? "none" : "none"
        
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius:0,
            // padding:'0.5rem',
            minWidth:'8rem',
            border: 'none',
            maxWidth:'30rem',
            // border: state.isFocused ? '2px solid grey' : '1px solid grey',
            height:'2.5rem',
            borderColor:'grey',
            boxShadow:'none',
            minHeight:'2.5rem',
            backgroundColor:'none',
            // backgroundColor:'rgb(216 216 216)',
            ':hover':{
                borderColor:'grey',
                backgroundColor:'white'
            }

          // none of react-select's styles are passed to <Control />
        //   width: 200,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        },
        menuList: (provided, state) => ({
            ...provided,
            ":hover": {
                backgroundColor:"#C7D3D5"
            }

        }),
        DownChevron: (provided) => ({
            ...provided,
            backgroundColor:"red"
        }),
        dropdownIndicator: (provided,state) => ({
            // ...provided,
            // padding:0
            display:'none'
            // width:'1rem'
        }),
        menuList: (provided,state) => ({
            ...provided,
            // backgroundColor:'rgb(161 161 161 / 42%)'
            
        }),
        menu: (provided,state) => ({
            ...provided,
            // backgroundColor:'#F1D8A9',
            border:'none',
            borderRadius:0,
            top:'1.8rem',
            
        }),
        clearIndicator: (provided,state) => ({
            ...provided,
            padding:0
            // width:'1rem'
        }),

        indicatorSeparator: () => ({
            display:'none'
            // width:'1rem'
        }),
        valueContainer: (provided,state) => ({
            ...provided,
            fontSize:'1.5rem',
            padding:0,
            maxHeight: state.isFocused ? 'initial' : '2.5rem',
            flexWrap:'none',
           
            // maxWidth:'10rem',
            // overflowX:'scroll'
        }),
        input: (provided) => ({
            ...provided,
            paddingRight:'2rem'
        }),
        container: (provided) => ({
            ...provided,
            height:"2.5rem" 
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor:"white",
            border:"solid 1px rgba(0,0,0,0.2)",
            fontSize: "90%"
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontSize: "90%"
        }),
        singleValue: (provided) => ({
            ...provided,
            borderRadius:"2px",
            fontSize: "90%",
            overflow: 'hidden',
            padding: '3px',
            paddingLeft: '6px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            backgroundColor:"white",
            width:"auto",
            // maxWidth:"15rem"
        })
    }

    // const [optionsIndex, setOptionsIndex] = useState();
    const [value,setValue] = useState()

    const {options, multi,filter, label, width} = props

    function handleSelect(e) {
        console.log(e)
        const reset = {value: 'all', label: 'Alle', group: label}
        if(!e) {
            filter(reset)
            // setOptionsIndex(null)
            setValue(null)
            return
        }
        if(Array.isArray(e)) {
            if(e.length === 0) {
                filter(reset)
                // setOptionsIndex(null)
                setValue(null)
                console.log("CLEAR")
                return
            }
            const arr = e.map(opt=>opt.value)
            setValue(e)
            filter({group:label, value:arr})
            return
        } 
        filter(e)
        // setOptionsIndex(options.indexOf(e))
        // const newValue = [...value]
        // newValue.push(e)
        setValue(e)
        // setOptionsIndex()
    }
    useEffect(() => {
        if(value) {
            setValue(null)
            // handleSelect([])
            // setTimeout(()=> {
            // },200*props.index)
        }
    }, [props.clear]);


//width:"calc(50% - 2rem)"
    return (
        <SelectContainer> 
            {label ? <p>{renamingObj[label]}:</p> : null}
            <Select
                isMulti={multi}
                isClearable={true}
                options={options} 
                onChange={handleSelect}
                value={value}
                styles={customStyles}
                // theme={(theme) => ({
                //     ...theme,
                //     borderRadius: 0,
                //     colors: {
                //       ...theme.colors,
                //       primary25: 'blue',
                //       primary: 'black',
                //     },
                //   })}
                // value={options[optionsIndex]}
            />

        </SelectContainer>
    )
}

export default CustemSelect;