import React from 'react';
import styled from 'styled-components';

const SmallDot = styled.div`
    position:absolute;
    
    min-height:5px;
    min-width:5px;
    border-radius:50% ;
    background-color:  #b04f4fd1;
    /* top: 50%;
    left:50% ; */
    pointer-events:none;
    transform: translate(-50%, 50%);
    /* transform:translate(-100%,100%); */
    transition: bottom 0.7s cubic-bezier(0.1, 1.13, 0.38, 1.01),left 0.7s cubic-bezier(0.1, 1.13, 0.38, 1.01), height 1s cubic-bezier(0.1, 1.13, 0.38, 1.01), width 1s cubic-bezier(0.1, 1.13, 0.38, 1.01);
    /* transition:all 0.7s cubic-bezier(0.17, 1.32, 0.76, 1) ; */
    `
    // bottom: ${props => `${props.pos[1]*10}%`};
    // left: ${props => `${props.pos[0]*10}%`}; 

const DotContainer = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    pointer-events: none ;
`


function CloudDots(props) {                         
    // console.log(props.pos)

    const dots = props.pos.x.map((x,index) => {
        const posXY = [x,props.pos.y[index]]
        const count = getOccurrence(props.pos.x,props.pos.y,posXY)
        const size = Math.max(2,count/props.pos.x.length*100);
        // console.log()
        const style = {
                    opacity:`${props.show ? 0.5 : 0}`,
                    height:`${props.show ? size : props.mad*10}px`,
                    width:`${props.show ? size : props.mad*10}px`,
                    left:`${props.show ? x*10 : props.pos.xDef*10}%`,
                    bottom:`${props.show ? props.pos.y[index]*10 : props.pos.yDef*10}%`
                }
        return (
            <SmallDot key={`${x}_${index}_${props.id}`} style={style}/>
        )
    })
    function getOccurrence(array1,array2, posXY) {
        let count = 0;
        array1.forEach((x,index) => {
            if(x === posXY[0] && array2[index] === posXY[1]) {
                count++
            }
        })
        // array.forEach((v) => (v === value && count++));
        return count;
    }

    return ( 
        <React.Fragment>
            {dots}
        </React.Fragment>
        // <DotContainer>
        // </DotContainer> 
    )
}

export default CloudDots;