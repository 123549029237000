import React from 'react';
import styled from 'styled-components';

const StyledTerm = styled.div`
    display:${props => props.isShown ? 'flex' : 'none'};
    /* background-color: var(--blue) ; */
    /* color:white; */
    /* color:${props => !props.isShown ? 'red' : 'black'}; */
    cursor: ${props => props.isClickable ? "pointer" : "initial"};
    &:hover > div {
        
        border: ${props => props.isClickable ? "solid 2px rgba(0,0,0,0.65)" : "1px solid rgba(0,0,0,0.35)"};
    }
    span {
        width:30%;
        text-align: right;
        align-self: center;
        margin-right: 1rem;
    }
    i {
        align-self: center;
        width:5%;
        margin-left:1rem;
    }
    height: 3rem;
    margin: 0.5rem 0;
    `
// Example:
//   const Component = styled.div.attrs(props => ({
    //     style: {
        //       background: props.background,
        //     },
        //   }))`width: 100%;`
        
const SpreadIndicator = styled.div`
    position: relative;
    width: 60%;
    background-size: 10.05% 100%;
    background-position-x: -3px;
    background-color: white;
    background-image:linear-gradient(90deg,transparent 0%,rgba(0,0,0,0.2) 2%,rgba(0,0,0,0.2) 2.5%,transparent 0.9%);
    box-sizing: border-box;
    border-radius:5px;
    /* overflow: ; */
    /* border:1px solid black; */
    /* border-radius: 5px; */
    border:${props => props.isSelected ? "solid 2px rgba(0,0,0,0.65)" : "1px solid rgba(0,0,0,0.35)"};
    
    #spread, #median {
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        opacity: ${props => props.mode ? 1 : 0};
        transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
    }
    #spread {
        

        background-color: #D9D9D9;
        height: calc(100% - 1rem);
        
        
    }
    #median {
        height: 90%;
        width: 0.75rem;
        background-color: #1C3557;
        border-radius: 5px;
    }
    #fordeling {
        /* opacity: 0.7; */
        color:black;
        width: calc(100% + 2px);
        /* height: 100%; */
        display: flex;
        height:calc(100% + 2px);
        transform: translate(-1px,-1px);
        
        & > .rounded {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        & > .rounded ~ .rounded, & > .rounded ~ .notrounded {
            border-radius: 0;
        }
        .rounded:last-of-type  {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        /* overflow: visible; */
        div{
            display: ${props => props.mode ? "none" : "flex"};
            justify-content: center;
            align-items: center;

            transition: width 0.5s ease-in-out;
            p {
                /* z-index: 100; */
                
            }

        }
    }
    &:hover {
        #fordeling {
            div {
                display: flex;
            }
        }
        #median,#spread {
            /* display: none; */
            opacity: 0;
        }
    }
`

function Term(props) {

    const {selected,handleSelect,mode, result: {mad,median, fordeling, weight}, data:{Element,ID}, isShown} = props;
    // #FF0000
    // #FF6600
    // #FFFF00
    // #B3FF00
    // #66FF00
    // const opac = 0.6
    const colors = ["rgba(255, 0, 0,0.65)","rgba(255, 102, 0, 0.65)","rgba(255, 255,0, 0.65)","rgba(179, 255, 0,0.65)","rgba(102, 255, 0,0.65)"]
    const precentMedian = parseFloat(median) * 10;
    const medianStyle = {left:`${precentMedian}%`};
    const spreadStyle = {...medianStyle,width:`${mad*10}%`};

    let checkIfFirst = false;
    const fordelingComps = fordeling.map((int,index) => {
        const percent = (int / weight.length) * 100
        const fordelingsProcentStr = `${Math.round(percent)}%`
        const style = {width:`${percent}%`,backgroundColor:`${colors[index]}`}
        const isAtEnd = int > 0
        return <div className={isAtEnd ? "rounded" : "notRounded"} style={style} key={index}><p>{percent > 4 ? fordelingsProcentStr : null}</p></div>
    })

    function handleClick() {
        if(props.result.exp) {
            handleSelect(ID.toLowerCase())
        }
    }
    
    // console.log(fordelingStyle)

    //SpreadStyle left should be calculated so it never goes outside the borders!!!
    // onMouseOver={()=>handleHover([ID.toLowerCase()])
    // console.log(props.result)
    //- median:{props.result.median} - spredning:{props.result.mad} - count:{props.result.weight.length
    return (
        <StyledTerm isShown={isShown}  onClick={handleClick} isClickable={props.result.exp ? true : false}>
            <span>{Element}</span>
            <SpreadIndicator isSelected={selected} median={precentMedian} mode={mode}>
                <div id="spread" style={spreadStyle}></div>
                <div id="median" style={medianStyle}></div>
                <div id="fordeling">
                    {fordelingComps}
                </div>
            </SpreadIndicator>
            <i>{median}</i>
        </StyledTerm>
    )
}

export default Term