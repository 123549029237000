import styled from "styled-components";

export const Expander = styled.div`
    
    background-color:rgb(161 161 161 / 42%);
    transition:all 0.15s ease-in-out ;
    max-height:${props=> props.expanded ? "50vh" : "3rem"};
    height:auto;
    /* overflow:hidden; */
    position: fixed;
    /* top:0;
    right:0; */
    max-width:${props=> props.expanded ? "50vw" : "15rem"};
    min-width:${props=> props.expanded ? "35vw" : "15rem"};
    z-index:100;
    padding:${props=> props.expanded ? " 0 2rem 2rem 2rem" : "0rem"};
    /* border-top-left-radius:10px ; */
    border-bottom-left-radius:10px ;
    backdrop-filter: ${props=> props.expanded ? 'blur(6px)': 'blur(0px)'};
    transform-origin: top right;

    &:hover {
        transform: ${props=> props.expanded ? 'scale(1)' : 'scale(1.1)'};
    }


    h1 {
        text-align:${props=> props.expanded ? "left" : "center"};
        padding: 0.5rem;
        cursor: pointer;
        /* &:hover {

        } */
    }
`