import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
const GlobalStyle = createGlobalStyle`

/* $blue: #1c3557d1; */
    :root {
        --turk100:#A8DADD;
        --turk80:#C7D3D5;
        --sand100:#F1D8A9;
        --sand80:#E3CCA4;
        --red100:#F16568;
        --red80:#E89397;
        --blue100:#1C3557;
        --blue80:#767A7A;
    }
    ${reset}
    @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
    html,body {
        font-size:62.5%;
    }
    body {
        font-family: 'Roboto', sans-serif;
        /* background-color: var(--blue100); */
    }
    a,p,span,i,h1 {
        font-size: 1.5rem;
    }
    /* h1 {
    font-
    } */
    button {
        border:none;
        box-sizing: border-box;
        padding: 1rem;
        /* border:1px solid var(--turk80); */
        background-color:var(--turk80);
        margin:0 0.5rem 0.5rem 0;
        cursor:pointer;
        &:hover {
            background-color:var(--turk100);
            /* border:1px solid var(--turk100); */
        }
        &:active {
            background-color:var(--blue80);
        }

    }
`

export default GlobalStyle