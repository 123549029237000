import React, { useState, useContext } from 'react';
import { StateContext } from '../context/StateContext';
import variablesData from '../data/variables.json'
import CustemSelect from './CustomSelect';
import FilterPreset from './FilterPreset';
import {Expander} from '../styledComps/StyledComps'
import FilterButtons from './FilterButtons';
import styled from 'styled-components';


const FilterContainer = styled.div`
    /* background-color:rgb(161 161 161 / 42%) ; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    & > * {
        margin-left:1rem ;
    }
`

function Filtering(props) {

    const getAllCategories = Object.keys(variablesData).map(term => variablesData[term].Kategori)
    const categories = getAllCategories.filter((item,index) => getAllCategories.indexOf(item) === index).filter(item => item !== '');
    
    const {setFilterObj,initialFilter, filterObj} = useContext(StateContext)
    const [expanded, setExpanded] = useState(false);
    const [clear, setclear] = useState(false);

    const filterOptions = {
        "Kategori": categories,
        "hasCost":["all",'Ja','Nej'],
        "median":[1,-1,0], //0 = all, 1 = higher than 5.5, -1 = lower or equal 5.5
        "hasValue":["all",'Ja','Nej'],
        "offered_NNIT":['Ja','Nej'],
        "exp":['Ja','Nej']
    }
    
    function filter(e) {
        console.log(e)
        const newObj = {...filterObj}
        newObj[e.group] = e.value;

        setFilterObj(newObj)
    }
    //FOR THE PRESET BUTTONS
    // function presetFilter(obj) {
    //     const newObj = {...filterObj,...obj}
    //     setFilterObj(newObj)
    // }

    function handleClear(e) {
        const newState = !clear
        setclear(newState)
        setFilterObj(initialFilter)
        // setTimeout(()=>{setclear(false)},400)
    }

    const filterComps = Object.keys(initialFilter).map(key => {
        if(key === "Kategori" || key === "offered_NNIT" || key === 'exp') {
            const options = filterOptions[key].map(seg => {
                return {value:seg, label:seg, group:key}
            })
    
            return (
                <CustemSelect
                    filter={filter}
                    key={key}
                    options={options}
                    label={key}
                    multi={false}
                    clear={clear}
                    width={"100%"}
                />
            )

        }
    })

    // const presetTitles = ['Stop','Keep', 'Consider', 'Observe'];

    // const presetObj = {
    //     "Stop": {"offered_NNIT":'Ja','median':-1},
    //     "Keep":{"offered_NNIT":'Ja','median':1},
    //     "Consider":{"offered_NNIT":'Nej','median':1},
    //     "Observe": {"offered_NNIT":'Nej','median':-1}
    // }

    // const presetButtons = Object.keys(presetObj).map((title,index) => {
    //     const obj = presetObj[title]
    //     return (
    //         <FilterPreset filter={presetFilter} title={title} key={index} preset={obj}/>
    //     )
    // })

    return (
        <FilterContainer>
            <h1>FILTER</h1>
            <div style={{display:"flex"}}>
                {filterComps}<br></br>
            {/* <button onClick={handleClear}>Clear All</button><br></br> */}
            </div>
                <FilterButtons setLimitShown={props.setLimitShown}/>
            {/* {presetButtons}({props.countShown}) */}
        </FilterContainer>
    );
}

export default Filtering;