import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import CloudDots from './CloudDots';
import useLongPress from '../assets/longPressEvent';
const Dot = styled.div`


    background-color:  #1c3557d1;
    /* background-color:  #1C3557; */
    position: absolute;
    left: ${props => `${props.obj.x*10}%`};
    bottom: ${props => `${props.obj.y*10}%`};
    height:${props => `${props.obj.mad*10}px`};
    width:${props => `${props.obj.mad*10}px`};
    min-height: 5px;
    min-width: 5px;
    border-radius: 50%;
    border:${props => props.showCloud ? "#b04f4fd1 2px solid" : "#95c9e194 1px solid"};
    transition: left 0.5s ease-in-out, bottom 0.5s ease-in-out, height 0.5s ease-in-out, opacity 0.5s ease-in-out, width 0.5s ease-in-out, transform 0.1s linear ;
    opacity: ${props => props.active ? 1 : 0.1};
    pointer-events:all ;
    cursor:pointer;
    span {
        display: none;
        font-size:10px;
        width: 150px;
        transform: translate(-40%, 30px);
        text-align: center;
    }
    transform: translate(-50%, 50%);
    &:hover {
        z-index:100;
        transform:translate(-50%, 50%) scale(1.3);
        opacity: 1;
        span {
            display: block;
        }
    }
    &::after {
        content: ${props => `${props.title}`};
    }
`

const DotContainer = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    pointer-events: none ;
    
`

function Dots(props) {

    const {setshowCloudID, showCloudID,hideUnselected, active,t,title, weight, exp, showOnly} = props

    const [showCloud, setShowCloud] = useState(false);
    // console.log(showOnly)
    const defaultOptions = {
        shouldPreventDefault: false,
        delay: 500,
      };
    const onClick = () => {
        props.select(t.id);
        // console.log(event)
        props.showOnly(1)
        setShowCloud(false)
    }
    const onLongPress = () => {
        console.log("longpress")
        setShowCloud(!showCloud)
        setshowCloudID(t.id)
    }

    useEffect(() => {
        if(showCloudID !== t.id) {
            setShowCloud(false)
        }
    }, [showCloudID]);





    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    

    return (
        <DotContainer key={t.id}>
        <Dot
            showCloud={showCloud}
            hide={hideUnselected && !active.includes(t.id)}
            title={title}
            key={t.id} 
            obj={{x:t.median,y:t.expMedian,mad:t.mad}} 
            active={active.includes(t.id) || !active.length} 
            // onClick={()=> {props.select(t.id);props.showOnly(1)}}
            {...longPressEvent}
        >
            <span>{title}</span>
        </Dot>
        <CloudDots 
            show={showCloud}
            id={t.id}
            mad={t.mad}
            pos={{xDef:t.median,yDef:t.expMedian, x:weight, y:exp}} 
        />

        

    </DotContainer>
    );
}

export default Dots;