import React, {useContext} from 'react';
import { StateContext } from '../context/StateContext';

function FilterPreset() {
    const {setFilterObj, filterObj} = useContext(StateContext)

    function presetFilter(obj) {
        const newObj = {...filterObj,...obj}
        setFilterObj(newObj)
    }
    const presetObj = {
        "Stop": {"offered_NNIT":'Ja','median':-1},
        "Keep":{"offered_NNIT":'Ja','median':1},
        "Consider":{"offered_NNIT":'Nej','median':1},
        "Observe": {"offered_NNIT":'Nej','median':-1}
    }

    const presetButtons = Object.keys(presetObj).map((title,index) => {
        const obj = presetObj[title]
        return (
            <button onClick={()=>{presetFilter(obj)}} key={index}>{title}</button>
        )
    })

    // const {filter,preset,title} = props

    return (
        <div>
            {presetButtons}
        </div>
        // <button onClick={()=>{filter(preset)}}>{title}</button>
    );
}

export default FilterPreset;