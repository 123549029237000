import './App.css';
import React, {useState, useEffect, useContext} from 'react';
import data from './data/variables.json';
import Term from './components/Term';
import {StateContext} from './context/StateContext'
import SegmentingControl from './components/Segmenting';
import Filtering from './components/Filtering';
import SortingControl from './components/Sorting';
// import { CSVLink, CSVDownload } from "react-csv";
import styled from 'styled-components';

import CvsDownloads from './components/CvsDownloads';
import Matrix from './components/IndexMatrix';
import FilterButtons from './components/FilterButtons';


const GridContainer = styled.div`
  width:100vw;
  height: 100vh;
  display:grid;
  grid: 15% 7% 31.25% 34.25% 12.5% / 60% 20% 20%;
  grid-auto-rows: 100px;
  grid-template-areas: 
  "filter test2 segment"
  "sort test2 segment"
  "terms index index"
  "terms index index"
  "buttons empty empty";

  overflow:hidden;
`

const ButtonContainer = styled.div`

`
// [{id:id, name:title}]
const searchableVar = Object.keys(data).filter(id => data[id].use === 'TRUE').map(term => {
    return {id:term.toLocaleLowerCase(), name:data[term].Element}
})
  
function App() {
// console.log(searchableVar)
  const {filteredResults, filterTerm,filterObj, sortObj} = useContext(StateContext)
  
  const [termsComps, setTermsComps] = useState();
  // const [allTermsComps, setAllTermsComps] = useState();
  const [countShown, setCountShown] = useState();
  const [toggle, setToggle] = useState(1);
  const [selectedTerm, setselectedTerm] = useState([]);
  const [showOnly, setShowOnly] = useState(0);
  const [searchStr, setSearchStr] = useState('')
  const [foundTerms, setfoundTerms] = useState([])

  const [limitShown, setlimitShown] = useState(-1)

  // useEffect(() => {
  //   setselectedTerm([])
  // }, []);

  function handleSelect(id) {
    if(selectedTerm) {
      let newSelect = [...selectedTerm]
      if(!selectedTerm.includes(id)) {

        newSelect.push(id)
      } else {
        newSelect = selectedTerm.filter(t => t !== id);
      }
      console.log(newSelect)
      setselectedTerm(newSelect)
    }
  }

  // useEffect(() => {
  
  // }, []);

  function handleSearch(e) {
    console.log(e)
    const str = e.target.value;
    const found = searchableVar.filter(el => {
      const name = el.name.toLocaleLowerCase();
      return name.includes(str.toLocaleLowerCase()) ? 1 : false
    }).map(obj => obj.id)
    console.log(found)
    setSearchStr(str);
    setfoundTerms(found)
    if(str === "") {
      setfoundTerms([])
      setShowOnly(0)
    } else {
      setShowOnly(1)

    }
    // setfoundAny(found.length > 0 ? true : false)

  }

  function filterSort(count = false, get = false) {
    if(filteredResults) {
      console.log(filteredResults)
      
      let counter = 0;
      const terms = Object.keys(filteredResults).map(id=>{
        // console.log(filteredResults)
        const termObj = data[id.toUpperCase()]
        let isShown;
        if(showOnly && selectedTerm.length || showOnly && foundTerms.length) {
          isShown = selectedTerm.includes(termObj.ID.toLowerCase()) || foundTerms.includes(termObj.ID.toLowerCase());
          // consolelog
        } else {
          isShown = filterTerm({...termObj,median:filteredResults[id].median})
        }
        if(isShown) {counter++}

        //TOP 10 filter
        if(counter > limitShown && limitShown > 0) {
          isShown = false;
        }
        
        if(termObj) {
          return <Term 
                  handleSelect={handleSelect}
                  selected={selectedTerm.includes(id)}
                  key={id} 
                  mode={toggle} 
                  data={termObj} 
                  result={filteredResults[id]} 
                  isShown={isShown} 
                />
        } 
      }) //END .MAP()
      
      //SORTING HAPPENS HERE ON terms
      // terms.forEach(t=> console.log(t.props.result.median))
      if(sortObj) {
        terms.sort(sortTerms) //two elements are send to function
      }
      setCountShown(counter)
  
      console.log(terms[3].props)
      if(count) {
        return terms.length
      }

      if(get) {
        return terms;
      }
      setTermsComps(terms)
      
    }

  }

  useEffect(() => {
    filterSort()
  }, [filteredResults,filterObj,sortObj,selectedTerm, showOnly,foundTerms, limitShown]);

  useEffect(() => {
    // filterSort()
    // console.log(termsComps)
    if(!termsComps) return;
    const terms = termsComps.map(comp =>{
      const id = comp.key
      console.log(comp.key)
      const termObj = data[id.toUpperCase()]
      return (
        <Term 
            handleSelect={handleSelect}
            selected={selectedTerm.includes(id)}
            key={id} 
            mode={toggle} 
            data={termObj} 
            result={filteredResults[id]} 
            isShown={comp.props.isShown} 
      />
      )
    })
    setTermsComps(terms)
  }, [toggle]);


  function sortTerms(a,b) {
    const medA = a.props.result[sortObj.group]
    const medB = b.props.result[sortObj.group]
    if(medA > medB) {
      return sortObj.direction[0]
    } 
    if(medA < medB) {
      return sortObj.direction[1]
    } 
    if(medA == medB) {
      return 0
    }

  }


//   function handleClear(e) {
//     // const newState = !clear
//     // setclear(newState)
//     setLimitShown(-1)
//     setFilterObj(initialFilter)
//     // setTimeout(()=>{setclear(false)},400)
// }

  return (
    <GridContainer>
      {/* <CvsDownloads/> */}
      {/* <div style={{gridArea:"segment"}}> */}
        <SegmentingControl />
      {/* </div> */}
      <div style={{gridArea:"filter"}}>
          <Filtering countShown={countShown} setLimitShown={setlimitShown}/>
      </div>
      <div style={{gridArea:"sort",alignSelf:"end", display:"flex"}}>
        <input style={{height:"2em",width:"calc(30% - 2rem)",marginLeft:"2rem"}} onChange={handleSearch} value={searchStr} placeholder="search..." type="text"/>
        <SortingControl limitShown={setlimitShown} />
        <div>
          {/* <Button click={{handle:setLimitShown,val:-1}} title="vis all toggle" /> */}
          <button onClick={()=> {setToggle(!toggle)}}>{!toggle ? "VIS SPREDNING" : "VIS FORDELING"}</button>
          {/* <FilterButtons setLimitShown={setLimitShown}/> */}
          
        </div>
      </div>
      <div style={{gridArea:"buttons"}}>
      </div>
      <Matrix active={selectedTerm} select={handleSelect} showOnly={setShowOnly} clearSelection={setselectedTerm} />
      <div style={{gridArea:"terms",overflowY:"scroll"}}>{termsComps}</div>
    </GridContainer>
  )
}
// onMouseLeave={()=> setselectedTerm([])}
export default App;
