import React, {useContext} from 'react'
import { StateContext } from '../context/StateContext';
import answersData from '../data/answersData.json'
import data from '../data/variables.json';
import { CSVLink } from "react-csv";

function CvsDownloads() {

    const {filteredResults,oplevetCVS} = useContext(StateContext);

    const checkThese = ["si_03","ff_03","ff_02","ff_06", "ff_07", "ff_08"]
    const csvPotent = [
    [
        "Pension Præf.", "Pension værdi",
        "Ferietillæg Præf", "Ferietillæg Værdi",
        "Feriefridage","Feriefridage værdi",
        "Fri med løn Grundlovsdag",
        "Fri med løn Juleaftensdag",
        "Fri med løn Nytårsaftensdag",
        "Løn"
    ]
    ]
    const cvsCount = []
  
    const cvsMatch = [
      ["Vilkår","Præf. (gns.)","Oplevet (gns.)",	"Offered",	"Famile/kategori"]
    ]
  
    if(filteredResults) {
  
      Object.keys(filteredResults).forEach(id => {
        const obj = filteredResults[id]
        if(parseFloat(obj.median) > 5.5) {
    
          const oplevetMedian = obj.exp ? obj.expMedian : null ;
    
          const row = [
            data[id.toUpperCase()]["Element"], 
            obj.median,
            oplevetMedian,
            data[id.toUpperCase()]["offered_NNIT"],
            data[id.toUpperCase()]["Kategori"]
          ]
    
          
          cvsMatch.push(row)
    
        }
      })
    }
    
    Object.keys(answersData[0].answers).forEach(id => {
      if(id !== "ft_01") {
        cvsCount.push([data[id.toUpperCase()]["Element"],0])
      }
    })
  
  
    answersData.forEach(emp => {
        const arr = []
        checkThese.forEach(item => {
            Object.keys(emp.answers).forEach(id => {
                if(id === item) {
                    Object.keys(emp.answers[id]).forEach(value => {
                        if(value !== "Oplevet"){
  
                          arr.push(emp.answers[id][value])
                        }
                    })
                    // csvPotent.push(arr)
                }
            })
        })
  
        // console.log(arr)
        arr.push(emp.salary)
        csvPotent.push(arr)
  
        //csvCount
        Object.keys(emp.answers).forEach((id,index) => {
            if(id !== "ft_01" && emp.answers[id].Vægt < 6) {
              cvsCount[index][1] = cvsCount[index][1] + 1
              // console.log(cvsCount[index])
  
            }
          // return [data[id.toUpperCase()]["Element"],0]
        })
        // cvsCount.push(allTerm)
        
    })
    cvsCount.unshift(["vilkår", "antal"]);
    // console.log(cvsCount);
  

    return (
        <div style={{gridArea:"empty"}}>
            <CSVLink data={cvsMatch}>Download CVS til Match</CSVLink><br></br>
            <CSVLink data={cvsCount}>Download CVS alle under 6 pref.</CSVLink><br></br>
            <CSVLink data={csvPotent}>Download CVS til Potentiale</CSVLink><br></br>
            {oplevetCVS ? 
            <CSVLink data={oplevetCVS}>Download CVS af segment (Kun Oplevet)</CSVLink>
            : null}

        </div>
    );
}

export default CvsDownloads;