import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import variables from '../data/variables.json'
import { StateContext } from '../context/StateContext';
import useLongPress from '../assets/longPressEvent';
import Dots from './Dots';


const MatrixContainer = styled.div`
    background-color: #D9D9D9;
    width: 400px;
    height: 400px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    border-radius:5px;

    .quadrant {
        width:50%;
        height:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border:1px solid black;
        box-sizing:border-box;
    }
`

function Matrix(props) {

    const {filteredResults} = useContext(StateContext)
    const {active} = props

    const [hideUnselected, sethideUnselected] = useState(false)
    const [offeredTerms, setOfferedTerms] = useState([]);
    const [dotComps, setDotComps] = useState();
    const [showCloudID, setshowCloudID] = useState("");

    
    useEffect(() => {
        if(filteredResults) {
            const terms = []
            Object.keys(filteredResults).forEach(id => {
                if(filteredResults[id].exp) {
                    terms.push({...filteredResults[id],id:id})
                }       
            })
            // console.log(terms)
            setOfferedTerms(terms);
        }
    }, [filteredResults, active]);
        
    useEffect(() => {
        const newDotComps = offeredTerms.map(t => {
            return (
                hideUnselected && !active.includes(t.id) ? null :
                    <Dots 
                        hideUnselected={hideUnselected} 
                        active={active}
                        t={t}
                        select={props.select}
                        showOnly={props.showOnly}
                        title={variables[t.id.toUpperCase()].Element}
                        weight={filteredResults[t.id].weight}
                        exp={filteredResults[t.id].exp}
                        showCloudID={showCloudID}
                        setshowCloudID={setshowCloudID}
                    />
                )
        })

        setDotComps(newDotComps)
        // console.log(newDotComps)
    }, [offeredTerms, hideUnselected, showCloudID]);

    // useEffect(() => {
    //     console.log(active.toLowerCase())
    //     setActiveState(active)
    // }, [props.active]);
    function handleClear() {
        props.showOnly(0)
        props.clearSelection([])
        sethideUnselected(false)
        setshowCloudID("")
    }

    return ( 
        <MatrixContainer style={{gridArea:"index"}}>
            <div className="quadrant" style={{borderTopLeftRadius:"5px"}}><p>STOP</p></div>
            <div className="quadrant" style={{borderTopRightRadius:"5px"}}><p>KEEP</p></div>
            <div className="quadrant" style={{borderBottomLeftRadius:"5px"}}><p>CONSIDER</p></div>
            <div className="quadrant" style={{borderBottomRightRadius:"5px"}}><p>IMPROVE</p></div>
                {dotComps}
            <div style={{marginTop:"20px"}}>
                {active.length ? 
                    <React.Fragment>
                        <button onClick={()=> {sethideUnselected(!hideUnselected)}}> {hideUnselected ? "show all" : "hide unselected"}</button> 
                        <button onClick={handleClear}> clear selection</button>
                        <button onClick={()=> {props.showOnly(1)}}> filter terms to selection</button>
                    </React.Fragment>
                    : null }
            </div>
        </MatrixContainer>
     );
}

export default Matrix;