import React, {useState} from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    border-radius:5px;
    color:black;
    /* box-sizing:border-box; */
    border: ${props => props.active ? "solid 2px #A8DADD" : "none"};
    background-color: ${props => props.active ? "#A8DADD" : "#C7D3D5"};
    /* &:hover {
        background-color: ${props => !props.active ? "#A8DADD" : "#C7D3D5"};
        
    } */
`

function Button(props) {


    const {handle,val, toggle, title} = props

    function handleClick(e) {
        handle(val)
        // settoggle(!toggle)
        toggle[1](title)

    }

    return (
        <StyledButton onClick={handleClick} active={toggle[0] === props.title}>
            {title}
        </StyledButton>
    )
}

export default Button;