import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import {StateContext} from '../context/StateContext'
import CustemSelect from './CustomSelect';
import rename from '../assets/reneameSegment.json'
import { Expander } from '../styledComps/StyledComps';

const StyledSegment = styled.div`
    visibility:${props=> props.expanded ? "visible" : "hidden"};
    /* width: 50%;
    min-width: 30rem;
    max-width: 45rem; */
    /* display:flex; */
    /* flex-wrap: wrap; */
`

function SegmentingControl() {

    const {countSegment,filterResults, segmentsOptions,resetSegment} = useContext(StateContext)

    const segmentObj = {
        "age":"all",
        "vp":"all",
        "superior":"all",
        "team":"all",
        "senioritDate":"all",
        "gender":"all",
        "office":"all",
        "positionDescription":"all",
        "salaryBandLevel":"all",
        "toe":"all"
    }

    const [segmentSelect, setSegmentSelect] = useState(segmentObj);
    const [clear, setclear] = useState(false);
    const [expanded, setExpanded] = useState(false);

    function filter(e, count = false,isMulti = false) {
        const newObj = {...segmentSelect}
        newObj[e.group] = e.value;
        if(!count) {
            filterResults(newObj, count, isMulti)
            setSegmentSelect(newObj)
            // console.log("CLEAR FILTER")
        } else {
            return filterResults(newObj, count, isMulti)
        }
    }


    const selectComps = Object.keys(segmentsOptions).map(key => {
        if( key === "senioritDate") return //key === "age" ||
        // const int = filterResults({})
        // const int = filter({value:seg,label:seg,group:key}, true)
        const isMulti = segmentsOptions[key].length > 2
        let options;

        if(key === "age") {
            options = segmentsOptions[key].sort((a,b)=> a - b).
            map(seg => {
                //COUNT HOW MANY RESULTS OPTION WILL PRODUCE
                const check = filter({value:seg,label:seg,group:key}, true, isMulti)
                // const disabled = false
                const disabled = check.int < 1
                // const preview = isMulti ? `(+${int})` : `(${int})`
                return {value:seg,label:`${seg} ${check.str}`,group:key, isDisabled:disabled}
            });
        } else {
            
            options = segmentsOptions[key].map(seg => {
                //COUNT HOW MANY RESULTS OPTION WILL PRODUCE
                const check = filter({value:seg,label:seg,group:key}, true, isMulti)
                // const disabled = false
                const disabled = check.int < 1
                // ANONYM VERSIO
                const label = rename[key] ? rename[key][seg] : seg;
                // NNIT VERSION
                // const label = seg;

                return {value:seg,label:`${label} ${check.str}`,group:key, isDisabled:disabled}
            });
        }

        return (
            <CustemSelect
                filter={filter}
                key={key}
                options={options}
                label={key}
                multi={isMulti}
                clear={clear}
                // width={"calc(33% - 0.5rem)"}
            
            />
        )
    })

    function handleClear(e) {
        console.log(e)
        const newState = !clear
        setclear(newState)
        resetSegment()
        setSegmentSelect(segmentObj)
        // setTimeout(()=>{setclear(false)},400)
    }

    return (
        <Expander expanded={expanded} style={{top:'0',right:'0'}}>
            <h1 onClick={()=> {setExpanded(!expanded)}}>SEGMENT ({countSegment()})</h1>
            <StyledSegment expanded={expanded}>
                {/* <h1>SEGMENTERING</h1> */}
                {selectComps}
                <button onClick={handleClear}>Clear All</button>
            </StyledSegment>
        </Expander>
    );
}

export default SegmentingControl;