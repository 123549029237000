import React, {useState, useContext} from 'react';
import Button from './Button';
import { StateContext } from '../context/StateContext';
// import { useState } from 'react';


function FilterButtons(props) {

    const {setFilterObj, filterObj,initialFilter} = useContext(StateContext)
    const [toggleFilterState, settoggleFilterState] = useState('VIS ALLE');
    const [togglePresetState, settogglePresetState] = useState('');
    const [currentLimit, setCurrentLimit] = useState(-1)

    function presetFilter(obj) {
        const newObj = {...filterObj,...obj}
        setFilterObj(newObj)

        // settoggleFilterState('VIS ALLE')
    }

    function togglePreset(val) {
        if(val === togglePresetState) {
            setFilterObj(initialFilter)
            settogglePresetState('')
        } else {
            settogglePresetState(val)

        }
    }
    const presetObj = {
        "Stop": {"offered_NNIT":'Ja','median':-1},
        "Keep":{"offered_NNIT":'Ja','median':1},
        "Introduce":{"offered_NNIT":'Nej','median':1},
        "Observe": {"offered_NNIT":'Nej','median':-1}
    }
    
    const presetButtons = Object.keys(presetObj).map((title,index) => {
        const obj = presetObj[title]
        return (
            // <button onClick={()=>{presetFilter(obj)}} key={index}>{title}</button>
            <Button handle={presetFilter} val={obj} title={title} key={index} toggle={[togglePresetState, togglePreset]}/>
        )
    })
    function handleReset(val) {
        props.setLimitShown(val)
        settoggleFilterState("VIS ALLE")
        settogglePresetState("")
        setFilterObj(initialFilter)
        setCurrentLimit(val)
    }
    function handleTopList(val) {
        props.setLimitShown(val)
        setCurrentLimit(val)
    }

    return (
        <div>
            <Button toggle={[toggleFilterState, settoggleFilterState]} title='VIS ALLE' val={-1} handle={handleReset} />
            <Button toggle={[toggleFilterState, settoggleFilterState]}  title='TOP 10' val={10} handle={handleTopList} />
            <Button toggle={[toggleFilterState, settoggleFilterState]}  title='TOP 5' val={5} handle={handleTopList} />
            <span style={{margin:"0 2rem"}}></span>
            {presetButtons}
        </div>
    );
}

export default FilterButtons;